import React from 'react';

import Layout from '../components/Layout';
import { Link } from 'gatsby';

export default function Contact({ children }) {
  return (
    <Layout funnelString="legal">
      <div>
        <div className="flex flex-wrap">
          <nav>
            <ul className="sticky top-20 ml-5 mt-10">
              <li>
                <Link to="/terms-of-use/">Plainice Terms</Link>
              </li>
              <li>
                <Link to="/dpa/">Data protection addendum</Link>
              </li>
              <li>
                <Link to="/privacy/">Privacy</Link>
              </li>
            </ul>
          </nav>
          {children}
        </div>
      </div>
    </Layout>
  );
}
