import React from 'react';
import { Link } from 'gatsby';
import Container from '@material-ui/core/Container';
import { useStaticQuery, graphql } from 'gatsby';

import LegalLayout from '../components/LegalLayout';
import Seo from '../components/seo';

export const Head = () => <Seo title="Terms of Use" />;

export default function IndexPage() {
  const {
    site: {
      siteMetadata: { siteUrl, title, apiUrl, support },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            apiUrl
            title
            support
          }
        }
      }
    `,
  );
  return (
    <LegalLayout>
      <Container maxWidth="md" component="main">
        <h1>Terms and Conditions</h1>
        <p>Last Updated Date: July 24, 2021</p>
        <h1>1. Introduction</h1>
        <p>
          These Terms of Service (these “Terms”) describe your rights and
          responsibilities when using our online connection programs and
          Services located at {siteUrl}, {apiUrl}, or via {title}’s app for
          Slack (collectively, the “Services”). Please read these Terms
          carefully; if you don’t agree to be bound by these Terms, you aren’t
          allowed to access or use the Services.
        </p>
        <h2>1.1 These Terms Form a Binding Contract</h2>
        <p>
          Unless the parties have mutually executed a different agreement
          covering your use of the Services, these Terms form a binding contract
          between Customer and Plainice. “We,” “our,” and “us” refers to
          Plainice, LLC , while “Customer” or “you” refers to you (or, as
          applicable, the company or entity that you represent). The “Effective
          Date” of these Terms is the date that you first use our website or
          access any of our Services. If you access or use the Services in your
          capacity as an employee, consultant or agent of a company or other
          entity, you represent that you are an employee, consultant or agent of
          that company or entity, and that you have the authority to bind that
          company or entity to these Terms.
        </p>
        <h2>1.2 These Terms Contain an Arbitration Provision</h2>
        <p>
          These Terms contain an arbitration provision (see Section 12, below).
          Please review the arbitration provision carefully, since it affects
          your rights by using the Services or any part of them, you understand
          and agree to be bound by the arbitration provision.
        </p>
        <h2>1.3 Ordering Subscriptions</h2>
        <p>
          A subscription allows you (and a specified number of your authorized
          users) to access the Services. A subscription can be obtained via the
          Services “billing” interface, or you can get a subscription via an
          order form entered into between the parties (an “Order Form”). We have
          several different types of Services subscriptions available, including
          a free plan (for which there are no subscription fees). One person or
          legal entity may not sign up for more than one free plan.
          Subscriptions commence when we make them available to you, and, unless
          terminated in accordance with these Terms, will continue for the term
          specified in the Services “billing” interface or in the Order Form, as
          applicable.
        </p>
        <h2>1.4 Making Your Purchasing Decisions</h2>
        <p>
          We may share information about our future product plans because we to
          get product feedback from you early and often. Our public statements
          about those product plans are an expression of intent, but please do
          not rely on them when making a purchase. If you decide to buy a
          subscription to our Services, that decision should be based on the
          functionality or features we have made available today, and not on the
          delivery of any future functionality or features.
        </p>
        <h2>1.5 Beta Products</h2>
        <p>
          Occasionally, we beta test new features. These features may be
          identified as “beta,” “pre-release,” or “early access,” or words or
          phrases with similar meanings (each, a “Beta Product”). Beta Products
          may not be ready for prime time so they are made available “as is,”
          and any commitments we make for other Services do not apply. Should
          you encounter any faults with our Beta Products, we would love to hear
          about them; our primary reason for running any beta programs is to
          iron out issues before making a new feature widely available.
        </p>
        <h2>1.6 We Want Your Feedback</h2>
        <p>
          The more suggestions our customers make, the better the Services
          become. If you send us any feedback or suggestions regarding the
          Services, there is a chance we will use it, so you grant us (on behalf
          of itself and its personnel) an unlimited, irrevocable, perpetual,
          sublicensable, transferable, royalty-free license to use any such
          feedback or suggestions for any purpose without any obligation or
          compensation to you or your personnel.
        </p>
        <h2>1.7 We Can Modify These Terms</h2>
        <p>
          We reserve the right to change or modify these Terms, or any of our
          other policies or guidelines, at any time upon notice to you. We may
          provide that notice in a variety of ways, including, among other
          things, sending you an email, posting a notice on the Service itself,
          or by posting the revised Terms on our website and revising the date
          at the top of these Terms. Any changes or modifications will be
          effective after we provide notice that these Terms have been modified.
          You acknowledge that your continued use of the Services following such
          notice constitutes your acceptance of the modified Terms.
        </p>
        <h2>1.8 We Can Also Modify the Services</h2>
        <p>
          We reserve the right — at any time, and without notice or liability to
          you — to modify the Services, or any part of them, temporarily or
          permanently. We may modify the Services for a variety of reasons,
          including, among other things, adding new features, implementing new
          protocols, maintaining compatibility with emerging standards, or
          complying with regulatory requirements.
        </p>
        <h1>2. Accessing and Using the Services</h1>
        <h2>2.1 Access Grant</h2>
        <p>
          Subject to your continued compliance with these Terms, Plainice grants
          you a limited, non-transferable, non-exclusive, revocable right and
          license to access and use the Services, solely for your own internal
          business purposes, for the subscription term for which you have paid
          the applicable fees. Depending on the plan you choose, you may also be
          subject to limitations on the number of active users who can use the
          Services, and/or the number of Slack workspaces and/or number of
          rotation schedules on which the Services can be active. These details
          will be made clear in your plan description, or set forth in the
          applicable Order Form.
        </p>
        <h2>2.2 Restrictions</h2>
        <p>
          Except as expressly authorized by these Terms, you may not: (a)
          modify, disclose, alter, translate or create derivative works of the
          Services; (b) license, sublicense, resell, distribute, lease, rent,
          lend, transfer, assign or otherwise dispose of the Services (or any
          part of them); (c) offer any part of the Services on a timeshare or
          service bureau basis; (d) allow or permit any third party to access or
          use the Services; (e) use the Services to store or transmit any
          viruses, software routines, or other code designed to permit anyone to
          access in an unauthorized manner, disable, erase or otherwise harm
          software, hardware, or data, or to perform any other harmful actions;
          (f) build a competitive product or service, or copy any features or
          functions of the Services (including, without limitation, the
          look-and-feel of the Services); (g) interfere with or disrupt the
          integrity or performance of the Services; (h) publicly disclose to any
          third party any performance information or analysis relating to the
          Services; (i) remove, alter or obscure any proprietary notices in or
          on the Services, including copyright notices; (j) use the Services or
          any product thereof for any illegal or unauthorized purpose, or in a
          manner which violates any laws or regulations in your jurisdiction;
          (k) reverse engineer, decompile, disassemble, or otherwise attempt to
          discover the source code, object code, or underlying structure, ideas,
          or algorithms that make up the Services or any software,
          documentation, or data relating to the Services, except to the limited
          extent that applicable law prohibits such a restriction; or (l) cause
          or permit any third party to do any of the foregoing.
        </p>
        <p>
          Additionally, you agree that you will not use the Services in support
          of a business that is dedicated to — or focused on facilitating or
          encouraging — discriminatory, malicious, or harmful speech or actions.
          This includes, but is not limited to, hate speech and any other
          material that Plainice reasonably believes degrades, intimidates, or
          incites violence against people based on gender, race, sexual
          orientation, age, ethnicity, disability, national origin, religion, or
          other protected categories.
        </p>
        <h2>2.3 Using the Services</h2>
        <p>
          You must comply with these Terms, and ensure that your users do, as
          well. We may review conduct for compliance purposes, but we have no
          obligation to do so. We aren’t responsible for the content of any
          Customer Data (defined below), or the way you or your users choose to
          use the Services to store or process any Customer Data. The Services
          are not intended for and should not be used by anyone under the age of
          16. You must ensure that all users are over 16 years old.
        </p>
        <h2>2.4 Our Removal Rights</h2>
        <p>
          If we believe that there is a violation of these Terms that can simply
          be remedied by your removal of certain Customer Data, we will, in most
          cases, ask you to take direct action rather than intervene. However,
          we may directly step in, without notice or liability to you, and take
          what we determine to be appropriate action (including, among other
          things, removing certain Customer Data) if you do not take appropriate
          action, or if we believe there is a credible risk of harm to us, the
          Services, your users, or any third parties.
        </p>
        <h2>2.5 Third Party Vendors</h2>
        <p>
          You understand that we use third-party vendors and hosting partners to
          provide the necessary hardware, software, networking, storage, and
          related technology required to provide the Services, and you agree
          that, except to the extent required by law, we are not and will not be
          liable or responsible for the acts or omissions of such third-party
          vendors or hosting partners.
        </p>
        <h2>2.6 Registration</h2>
        <p>
          In order to access certain features of the Services, you will need to
          have a valid account on Slack or another supported collaboration
          service (each, a “Third Party Account”). As part of your registration,
          you may be required to link your {title} account with a Third Party
          Account. Such a linkage will enable us to access your Third Party
          Account in accordance with that third party’s applicable terms. By
          giving us access to your Third Party Accounts: (a) you represent that
          you have the right to do so, without cost or liability to us; and (b)
          you acknowledge and agree that we may access, process, store, and/or
          use any Customer Data (defined below) that is visible or which you
          have stored on your Third Party Account for the purpose of making the
          Services available to you. You acknowledge that your relationship with
          your Third Party Account provider(s) is governed solely by your
          agreement(s) with such third party service providers, and Plainice
          specifically disclaims any liability for any Customer Data or other
          information (whether personally-identifiable or not) that may become
          accessible to Plainice via the Services by such third party service
          providers as a result of any linkage to a Third Party Account.
        </p>
        <h2>2.7 First, You Need a Slack License</h2>
        <p>
          You acknowledge and agree that the availability of the Services is
          dependent upon your entering into a valid license to use Slack. You
          acknowledge that these Terms are between you and Plainice, and not
          with Slack. You agree to comply with, and your license to use the
          Services is conditioned upon your compliance with, all applicable
          third-party terms (including, among other things, Slack’s terms of
          use) when using the Services. THE SLACK SERVICE AND ANY OTHER THIRD
          PARTY PRODUCT THAT YOU MAY CHOOSE TO USE ARE NOT OUR PRODUCTS, AND WE
          DO NOT WARRANT OR SUPPORT THOSE PRODUCTS IN ANY WAY, AND, ULTIMATELY,
          YOU (AND NOT US) WILL DECIDE WHETHER AND HOW YOU USE THOSE THIRD PARTY
          PRODUCTS. ANY USE OF THE SLACK PRODUCT OR ANY THIRD PARTY PRODUCT IS
          SOLELY BETWEEN YOU, SLACK, AND/OR THE THIRD PARTY PROVIDER, AS
          APPLICABLE.
        </p>
        <h2>2.8 You are Responsible for Your Access to the Services</h2>
        <p>
          You are responsible for maintaining the security of the accounts that
          you use to access the Services. You agree that you will be fully
          responsible for any activities or transactions that take place using
          your account(s), even if you were not aware of them, unless those
          activities or transactions take place as the direct result of our
          gross negligence or willful misconduct.
        </p>
        <h1>3. Privacy Policy</h1>
        <p>
          Please review our Privacy Policy at {siteUrl}/privacy for more
          information on how we collect and use data relating to the use and
          performance of our websites and products. By using the Services, you
          consent to our collection, use, and disclosure of information as set
          forth in our Privacy Policy, as we may update that Policy from time to
          time.
        </p>
        <h1>4.Payment Obligations</h1>
        <h2>4.1 Payment Terms</h2>
        <p>
          For Customers that purchase our Services, fees are specified at the
          Services “billing” interface or in the applicable Order Form(s), and
          must be paid in advance. By providing us with your credit card
          information, you agree: (a) that you have the right to provide that
          information to us; (b) that we are authorized to charge that card for
          all fees due to us hereunder, and that no additional notice or consent
          is required; (c) that if your Services subscription is billed based on
          the number of active {title} users (as determined by Plainice on a
          pro-rata basis), we are authorized to charge your credit card for
          those amounts (including, as applicable, periodic true-up charges);
          and (d) that you will keep your credit card information up-to-date.
          Payment obligations are non-cancelable and, except as expressly stated
          in these Terms, fees paid are non-refundable. For clarity, in the
          event you downgrade any subscription from a paid plan to a free plan,
          you will remain responsible for any unpaid fees under the paid plan,
          and Services under the paid plan will be deemed fully performed and
          delivered upon expiration of the initial paid plan subscription term.
          If we agree to invoice you by email, full payment must be received
          within thirty (30) days from the invoice date. Fees are stated
          exclusive of any taxes, levies, duties, or similar governmental
          assessments of any nature, including, for example, value-added, sales,
          use or withholding taxes, assessable by any jurisdiction
          (collectively, “Taxes”). You will be responsible for paying all Taxes
          associated with your purchases, except for those taxes based on our
          net income. Should any payment for the Services be subject to
          withholding tax by any government, you will reimburse us for such
          withholding tax. You agree to make all payments in U.S. Dollars,
          unless the Order Form indicates otherwise.
        </p>
        <h2>4.2 Credits</h2>
        <p>
          Depending on your Services subscription plan, you may receive credits
          for overages paid in prior months. You understand and agree that any
          credits that may accrue to your account will expire following
          expiration or termination of the applicable subscription, will have no
          currency or exchange value, and will not be transferable or
          refundable.
        </p>
        <h2>4.3 Downgrade for Non-Payment</h2>
        <p>
          If you are late in paying us, you also agree that, in addition to our
          rights to suspend your access to the Services, terminate your
          account(s), downgrade you to a free plan, and/or pursue any other
          rights or remedies available to us at law or in equity, you are
          responsible to reimburse us for any costs that we incur while
          attempting to collect such late payments. Customer acknowledges and
          agrees that any downgrade to a free plan may result in a decrease in
          certain features and functionality, and potential loss of access to
          Customer Data, and that we do not accept any liability for such loss.
        </p>
        <h1>5. Our Responsibilities</h1>
        <h2>5.1 Protecting Customer Data</h2>
        <p>
          The protection of Customer Data is a top priority for us so we will
          maintain administrative, physical, and technical safeguards at a level
          not materially less protective than as described in our Data
          Protection Addendum set forth at <Link to="/dpa/">{siteUrl}/dpa</Link>{' '}
          (the “DPA”), which is incorporated into these Terms by this reference.
          Those safeguards will include measures for preventing unauthorized
          access, use, modification, deletion and disclosure of Customer Data by
          our personnel. Before sharing Customer Data with any of our third
          party service providers, we will ensure that the third party
          maintains, at a minimum, reasonable data practices for maintaining the
          confidentiality and security of Customer Data and preventing
          unauthorized access. Customer (not us) bears sole responsibility for
          adequate security, protection and backup of Customer Data when in
          Customer’s or its representatives’ or agents’ possession or control.
          We are not responsible for what Customer’s users do with Customer
          Data, or what Slack or any other third party applications do with
          Customer Data — that is Customer’s responsibility.
        </p>
        <h2>5.2 Plainice Representatives</h2>
        <p>
          We may leverage our employees, those of our corporate affiliates and
          third party contractors (the “Plainice Representatives”) in exercising
          our rights and performing our obligations under these Terms. We will
          be responsible for the Plainice Representatives’ compliance with our
          obligations under these Terms.
        </p>
        <h1>6. Ownership and Proprietary Rights</h1>
        <h2>6.1 The Things You Own…</h2>
        <p>
          As between us on the one hand, and Customer on the other, Customer
          will own all data, material, or other content that Customer or its
          users make available or upload to the Services or to which we gain
          access via your Third Party Accounts (collectively, the “Customer
          Data”). Customer grants us a worldwide, non-exclusive, royalty-free,
          limited term license to access, use, process, copy, perform, export
          and display Customer Data, as reasonably necessary (a) to provide,
          maintain and update the Services; (b) to prevent or address service,
          security, support or technical issues; (c) as required by law or as
          permitted by the DPA; or (d) as expressly permitted in writing by
          Customer. Customer represents and warrants that: (i) it has secured
          all rights in and to Customer Data as may be necessary to grant this
          license; (ii) the Customer Data it or any user submits to the Services
          does not and will not infringe upon the intellectual property rights
          of any third party; (iii) the Customer Data it or any user submits to
          the Services does not and will not contain any “sensitive” personal
          data or “personal health information”, as defined by applicable laws,
          unless you have signed a separate written agreement with us relating
          to the processing of such data. Customer acknowledges that it is
          entirely responsible for Customer Data, and for any harm or liability
          resulting from or arising out of Customer Data — this responsibility
          applies whether or not you were the original creator of the Customer
          Data.
        </p>
        <h2>6.2 …And the Things We Own</h2>
        <p>
          We own and will continue to own our Services, including all related
          intellectual property rights. We may make software components
          available, via app stores or other channels, as part of the Services.
          We grant to Customer a non-sublicensable, non-transferable,
          non-exclusive, limited license for Customer to use the object code
          version of these components, but solely as necessary to use the
          Services and in accordance with these Terms. All of our rights not
          expressly granted by this license are hereby retained.
        </p>
        <h2>6.3 Usage Data</h2>
        <p>
          We will collect general information about your configuration and use
          of the Services (collectively, “Usage Data”) as part of our efforts to
          continuously improve our Services, and you agree that we have the
          right to collect and use Usage Data for that purpose.
        </p>
        <h2>6.4 Aggregated or Anonymized Data</h2>
        <p>
          You acknowledge that we have the right to collect, use, and share data
          that has been aggregated or anonymized (so that it does not directly
          or indirectly identify you or your users) for the purpose of
          developing new services and features, as well as promoting our
          products and services through, for example, publishing analyses of
          general patterns and trends.
        </p>
        <h1>7. Term and Termination</h1>
        <h2>7.1 Term</h2>
        <p>
          These Terms will apply to you beginning on the Effective Date, and
          will continue for as long as you are accessing or using our website or
          any of our Services. As further described below, a free subscription
          continues until terminated, while a paid subscription has a term that
          may expire or be terminated. These Terms remain effective until all
          subscriptions ordered have expired or been terminated, or the Terms
          themselves are terminated. Termination of these Terms will terminate
          all subscriptions and all Order Forms.
        </p>
        <h2>7.2 Auto-Renewal</h2>
        <p>
          Unless an Order Form says otherwise, all subscriptions automatically
          renew (without the need to execute a renewal Order Form) for
          additional subscription terms of equivalent length, unless one party
          gives written notice to the other at least thirty (30) days before the
          end of that subscription term that it does not want the term to renew.
          We reserve the right to modify the fees for the Services at any time
          upon thirty (30) days’ prior notice to you, provided that the modified
          fees will not apply until the next renewal term.
        </p>
        <h2>7.3 Suspension</h2>
        <p>
          We, in our sole discretion, have the right to suspend your ability to
          use and/or access the Services, without liability, under the following
          circumstances: (a) for scheduled or emergency maintenance to the
          Services; (b) if we believe that you are using the Services in
          violation of these Terms or applicable law; (c) if we believe that
          your use of the Services poses a security risk to us or to any third
          party; (d) if required by law enforcement or government agency, or
          otherwise in order to comply with applicable law or regulation; or (e)
          if you fail to fulfill your payment obligations.
        </p>
        <h2>7.4 Termination for Cause</h2>
        <p>
          Either party may terminate these Terms upon notice to the other party
          if the other party breaches any of these Terms and such breach is not
          cured within thirty (30) days after the non-breaching party provides
          notice of the breach. Customer is responsible for the acts and
          omissions of its users, including for any breaches of these Terms by
          its users. We may terminate these Terms immediately upon notice to you
          if we reasonably believe that you are in breach of any of these Terms
          relating to our intellectual property or our Confidential Information
          (defined below).
        </p>
        <h2>7.5 Termination Without Cause</h2>
        <p>
          Customer may terminate its free subscriptions immediately without
          cause. We may also terminate Customer’s free subscriptions without
          cause, but we will provide Customer with thirty (30) days prior
          written notice.
        </p>
        <h2>7.6 Effect of Termination</h2>
        <p>
          Upon any termination for cause by Customer, we will refund Customer
          any prepaid fees covering the remainder of the term of all
          subscriptions after the effective date of termination. Upon any
          termination for cause by us, Customer will pay any unpaid fees
          covering the remainder of the term of those subscriptions after the
          effective date of termination. In no event will any termination
          relieve Customer of the obligation to pay any fees payable to us for
          the period prior to the effective date of termination. When these
          Terms terminate or expire: (a) you will no longer have the right to
          access or use the Services; (b) you will ensure that the Services no
          longer integrate with your Slack workspace; and (c) unless otherwise
          set forth in the DPA or in accordance with applicable law, we may
          delete all Customer Data in our systems or otherwise in our possession
          or under our control. You acknowledge that, so long as the Services
          remain integrated with your Slack workspace, we will have access to
          Customer Data, and we will not be liable or responsible to you with
          respect to our treatment, storage, or use of any Customer Data if you
          fail to dis-integrate the Services from your Slack workspace following
          the expiration or termination of these Terms.
        </p>
        <h2>7.7 Survival</h2>
        <p>
          Sections 1.2, 1.6, 2.2, 2.4, 2.7, 4, 6, 7.6, 7.7, and 8 through 13
          will survive the termination or expiration of these Terms for any
          reason.
        </p>
        <h1>8. Disclaimer of Warranties</h1>
        <p>
          EXCEPT AS EXPRESSLY PROVIDED FOR HEREIN, THE SERVICES AND ALL RELATED
          COMPONENTS AND INFORMATION ARE PROVIDED ON AN “AS IS” AND “AS
          AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND WE EXPRESSLY
          DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING
          THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A
          PARTICULAR PURPOSE, AND NON-INFRINGEMENT. CUSTOMER ACKNOWLEDGES THAT
          WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY,
          SECURE, OR ERROR-FREE.
        </p>
        <h1>9. Indemnity</h1>
        <p>
          You agree, at your sole expense, to defend, indemnify and hold
          Plainice (and its directors, officers, employees, consultants and
          agents) harmless from and against any and all actual or threatened
          suits, actions, proceedings (whether at law or in equity), claims,
          damages, payments, deficiencies, fines, judgments, settlements,
          liabilities, losses, costs and expenses (including, without
          limitation, reasonable attorneys’ fees, costs, penalties, interest and
          disbursements) arising out of or relating to (a) Customer Data; (b)
          your use of the Services; (c) your failure to pay any taxes that you
          owe under these Terms; and (d) any other actual or alleged breach of
          any of your obligations under these Terms. You will not settle any
          such claim in any manner that would require Plainice to pay money or
          admit wrongdoing of any kind without our prior written consent, which
          we may withhold in our sole discretion.
        </p>
        <h1>10. Limitation of Liability</h1>
        <p>
          IN NO EVENT WILL PLAINICE’S TOTAL, AGGREGATE LIABILITY TO YOU OR TO
          ANY THIRD PARTY ARISING OUT OF OR RELATED TO THESE TERMS OR YOUR USE
          OF (OR INABILITY TO USE) ANY PART OF THE SERVICES EXCEED THE TOTAL
          AMOUNT YOU ACTUALLY PAID TO PLAINICE IN SUBSCRIPTION FEES FOR THE
          SERVICES DURING THE SIX (6) MONTHS IMMEDIATELY PRIOR TO THE ACCRUAL OF
          THE FIRST CLAIM. MULTIPLE CLAIMS WILL NOT EXPAND THIS LIMITATION.
        </p>
        <p>
          IN NO EVENT WILL PLAINICE BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR
          ANY LOSS OF PROFITS, LOSS OF USE, LOSS OF REVENUE, LOSS OF GOODWILL,
          INTERRUPTION OF BUSINESS, LOSS OF DATA, OR ANY INDIRECT, SPECIAL,
          INCIDENTAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND
          ARISING OUT OF, OR IN CONNECTION WITH THESE TERMS OR YOUR USE (OR
          INABILITY TO USE) ANY PART OF THE SERVICES, WHETHER IN CONTRACT, TORT,
          STRICT LIABILITY OR OTHERWISE, EVEN IF PLAINICE HAS BEEN ADVISED OR IS
          OTHERWISE AWARE OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p>
          The limitations under this “Limitation of Liability” section apply
          with respect to all legal theories, whether in contract, tort or
          otherwise, and to the extent permitted by law. The provisions of this
          “Limitation of Liability” section allocate the risks under these Terms
          between the parties, and the parties have relied on these limitations
          in determining whether to enter into these Terms and the pricing for
          the Services. This “Limitation of Liability” section will be given
          full effect even if any remedy specified in these Terms is deemed to
          have failed of its essential purpose.
        </p>
        <h1>11. Confidentiality</h1>
        <h2>11.1 Confidential Information</h2>
        <p>
          Each party (“Disclosing Party”) may disclose “Confidential
          Information” to the other party (“Receiving Party”) in connection with
          these Terms, which is anything that reasonably should be understood to
          be confidential given the nature of the information and the
          circumstances of disclosure (including all Order Forms), as well as
          non-public business, product, technology and marketing information.
          Confidential Information of Customer includes Customer Data, while
          Confidential Information of Plainice includes any non-public elements
          of the Services. If something is labeled “Confidential,” that’s a
          clear indicator to the Receiving Party that the material is
          confidential. For the sake of clarity, we will always treat your Slack
          message content as your Confidential Information. Notwithstanding the
          above, Confidential Information does not include information that (a)
          is or becomes generally available to the public without breach of any
          obligation owed to the Disclosing Party; (b) was known to the
          Receiving Party prior to its disclosure by the Disclosing Party
          without breach of any obligation owed to the Disclosing Party; (c) is
          received from a third party without breach of any obligation owed to
          the Disclosing Party; or (d) was independently developed by the
          Receiving Party.
        </p>
        <h2>11.2 Protection and Use of Confidential Information</h2>
        <p>
          The Receiving Party will (a) take at least reasonable measures to
          prevent the unauthorized disclosure or use of Confidential
          Information, and limit access to those employees, affiliates and
          contractors who need to know such information in connection with these
          Terms; and (b) not use or disclose any Confidential Information of the
          Disclosing Party for any purpose outside the scope of these Terms.
          Nothing above will prevent either party from sharing Confidential
          Information with financial and legal advisors; provided, however, that
          the advisors are bound to confidentiality obligations at least as
          restrictive as those in these Terms.
        </p>
        <h2>11.3 Compelled Access or Disclosure</h2>
        <p>
          The Receiving Party may access or disclose Confidential Information of
          the Disclosing Party if it is required by law; provided, however, that
          the Receiving Party gives the Disclosing Party prior notice of the
          compelled access or disclosure (to the extent legally permitted) and
          reasonable assistance, at the Disclosing Party’s cost, if the
          Disclosing Party wishes to contest the access or disclosure. Without
          limiting the foregoing, please review the DPA for details on how
          requests may be made for the disclosure of Customer Data and how we
          will handle those requests. If the Receiving Party is compelled by law
          to access or disclose the Disclosing Party’s Confidential Information,
          the Disclosing Party will reimburse the Receiving Party for its
          reasonable cost of compiling and providing access to such Confidential
          Information as well as the reasonable cost for any support provided in
          connection with the Disclosing Party seeking a protective order or
          confidential treatment for the Confidential Information to be
          produced.
        </p>
        <h1>12 Dispute Resolution and Arbitration</h1>
        <p>
          Please read this Section 12 carefully. It requires you to arbitrate
          disputes with us, and limits the manner in which you can seek relief
          from us.
        </p>
        <p>
          All disputes arising out of or in connection with these Terms,
          including without limitation your access or use of the Services, will
          be referred to and finally resolved by arbitration under the
          Commercial Arbitration Rules of the American Arbitration Association.
          Each party will cover its own fees and costs associated with the
          arbitration proceedings; however, if the arbitrator finds that you
          cannot afford to pay the fees and costs reasonably associated with the
          arbitration proceedings, Plainice will pay them for you. The place of
          arbitration will be Salt Lake City, UT. You may choose to have the
          arbitration conducted by telephone, based on written submissions. The
          language of the arbitration will be English. The award of the
          arbitrator will be final and binding, and any judgment on the award
          rendered by the arbitrator may be entered in any court of competent
          jurisdiction. Notwithstanding the foregoing, Plainice may seek and
          obtain injunctive relief in any jurisdiction in any court of competent
          jurisdiction, and you agree that these Terms are specifically
          enforceable by Plainice through injunctive relief and other equitable
          remedies without proof of monetary damages.
        </p>
        <p>
          WITH RESPECT TO ANY DISPUTE ARISING OUT OF OR RELATED TO THESE TERMS,
          INCLUDING WITHOUT LIMITATION DISPUTES RELATED TO THE SERVICES: (I) YOU
          HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY; AND (II)
          YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF
          A CLASS OF CLAIMANTS IN ANY LAWSUIT, INCLUDING BUT NOT LIMITED TO
          CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.
        </p>
        <h1>13 General Provisions</h1>
        <h2>13.1 Publicity</h2>
        <p>
          Customer grants us the right to use Customer’s company name and logo
          as a reference for marketing or promotional purposes on our website
          and in other public or private communications with our existing or
          potential customers, subject to Customer’s standard trademark usage
          guidelines as provided to us from time-to-time.
        </p>
        <h2>13.2 Force Majeure</h2>
        <p>
          Except for payments due under these Terms, neither party will be
          responsible for any delay or failure to perform that is attributable
          in whole or in part to any cause beyond its reasonable control,
          including, without limitation, acts of God (fire, storm, floods,
          earthquakes, etc.); civil disturbances; pandemic; disruption of
          telecommunications, power or other essential services; interruption or
          termination of service by any service providers used by Plainice to
          host the Services or to link its servers to the Internet; labor
          disturbances; vandalism; cable cut; computer viruses or other similar
          occurrences; or any malicious or unlawful acts of any third party. For
          the sake of clarity, Plainice will not be responsible for any delay or
          failure under these Terms (or in the Services themselves) that is
          attributable to any act or omission of Slack or any third party.
        </p>
        <h2>13.3 Relationship of the Parties; No Third Party Beneficiaries</h2>
        <p>
          The parties are independent contractors. These Terms do not create a
          partnership, franchise, joint venture, agency, fiduciary or employment
          relationship between the parties. There are no third party
          beneficiaries to these Terms.
        </p>
        <h2>13.4 Email and Slack Messages</h2>
        <p>
          Except as otherwise set forth herein, all notices under these Terms
          will be by email, although we may instead choose to provide notice to
          you through the Services (e.g., a Slack app notification). Notices to
          Plainice will be sent to {support}, except for legal notices, such as
          notices of termination or any claim of breach, which must be sent to
          legal@plainice.com, with a copy to 285 N Main St Suite 322, Kaysville,
          UT 84037. Notices will be deemed to have been duly given (a) the day
          after they are sent, in the case of notices through email; and (b) the
          same day, in the case of notices sent through the Services.
        </p>
        <h2>13.5 Trademarks</h2>
        <p>
          You acknowledge and agree that any Plainice names, trademarks, service
          marks, logos, trade dress, or other branding included on our website
          or as part of the Services (collectively, the “Marks”) are owned by
          Plainice and may not be copied, imitated, or used (in whole or in
          part) without Plainice ’s prior written consent. All other trademarks,
          names, or logos referenced on our website or the Services
          (collectively, “Third-Party Trademarks”) are the property of their
          respective owners, and the use of such Third-Party Trademarks inure to
          the benefit of their respective owners. The use of such Third-Party
          Trademarks is intended to denote interoperability, and does not
          constitute an affiliation by Plainice or its licensors with any
          company or an endorsement or approval by that company of Plainice ,
          its licensors, or their respective products or services.
        </p>
        <h2>13.6 Waiver</h2>
        <p>
          No failure or delay by either party in exercising any right under
          these Terms will constitute a waiver of that right. No waiver under
          these Terms will be effective unless made in writing and signed by an
          authorized representative of the party being deemed to have granted
          the waiver.
        </p>
        <h2>13.7 Severability</h2>
        <p>
          These Terms will be enforced to the fullest extent permitted under
          applicable law. If any provision of these Terms is held by a court of
          competent jurisdiction to be contrary to law, the provision will be
          modified by the court and interpreted so as best to accomplish the
          objectives of the original provision to the fullest extent permitted
          by law, and the remaining provisions of these Terms will remain in
          effect.
        </p>
        <h2>13.8 Assignment</h2>
        <p>
          You may not assign these Terms, in whole or in part, by operation of
          law or otherwise, without the prior written consent of Plainice , and
          any attempted transfer, assignment or delegation without such consent
          will be void and of no effect. Plainice may freely transfer, assign or
          delegate these Terms, or its rights and duties under these Terms,
          without notice to you. Subject to the foregoing, these Terms will be
          binding upon and will inure to the benefit of the parties and their
          respective representatives, heirs, administrators, successors and
          permitted assigns.
        </p>
        <h2>13.9 Governing Law and Venue</h2>
        <p>
          These Terms, and any disputes arising out of or related hereto, will
          be governed exclusively by the laws of the State of New York, without
          regard to conflicts of laws rules or the United Nations Convention on
          the International Sale of Goods or the Uniform Computer Information
          Transactions Act (which are both hereby expressly disclaimed). To the
          extent that the arbitration requirements in Section 12 do not apply
          for any reason: (i) the courts located in Salt Lake City, UT will have
          exclusive jurisdiction to adjudicate any dispute arising out of or
          relating to these Terms or its formation, interpretation or
          enforcement, and each party hereby consents and submits to the
          exclusive jurisdiction of such courts; and (ii) in any action or
          proceeding to enforce rights under these Terms, the prevailing party
          will be entitled to recover its reasonable costs and attorney’s fees.
        </p>
        <h2>13.10 Compliance with Laws</h2>
        <p>
          You acknowledge that you are responsible for complying with all
          applicable laws and regulations associated with your access and use of
          the Services.
        </p>
        <h2>13.11 Entire Agreement</h2>
        <p>
          These Terms, together with any policies incorporated into these Terms
          by reference, constitute the entire agreement between the parties and
          supersedes all prior and contemporaneous agreements, proposals or
          representations, written or oral, concerning its subject matter
          (including, without limitation, prior superseded versions of these
          Terms). Any terms or conditions that you send to Plainice that are
          inconsistent with or in addition to these Terms are hereby rejected by
          Plainice , and will be deemed void and of no effect. Notwithstanding
          any language to the contrary therein, no terms or conditions stated in
          a Customer purchase order, vendor onboarding process or web portal, or
          any other Customer order documentation will be incorporated into or
          form any part of these Terms, and all such terms or conditions will be
          null and void. Except as expressly set forth in these Terms, the
          exercise by either party of its remedies will be without prejudice to
          its other remedies, whether under these Terms or otherwise.
        </p>
      </Container>
    </LegalLayout>
  );
}
